<template>
  <div class="m-jeff-bag">
    <aSection
      :title="section.title"
      :subtitle="section.subtitle"
      :background="'#fff'"
    >
      <div class="container bag">
        <div class="bag__image">
          <img src="@/assets/images/webp/location.webp" alt="jeffbag" />
        </div>
        <div class="bag__details">
          <h3 class="bag__details--title">{{ $t(details.title) }}</h3>
          <div
            v-for="(item, i) in details.description"
            :key="`description-${i}`"
            class="bag__details--description-container"
          >
            <p class="bag__details--description">
              {{ $t(item) }}
            </p>
          </div>
        </div>
      </div>
    </aSection>
  </div>
</template>
<script>
import aSection from "../../atoms/a-section.vue";

export default {
  components: {
    aSection,
  },
  data() {
    return {
      section: {
        title: "Despre serviciile noastre",
        subtitle: "Despre noi",
      },
      details: {
        title: "Cine este Mr Jeff?",
        description: [
          "Mr Jeff Brașov oferă servicii profesionale de curățare, spălare, călcare pentru orice fel de haine: de la tricou, pantaloni, cămașă până la geacă, sacou sau rochie.",
          "Articolele de casă sunt tratate de asemenea cu atenție: de la lenjerie, pernă, pilotă până la draperie, perdea sau pătură.",
          "Poți comanda prin telefon sau în locația fizică din Brașov. Îți vom ridica / aduce hainele la ușă prin serviciul rapid de colectare și livrare la domiciliu.",
          "Curățătoria - Spălătoria Mr Jeff Brașov oferă cea mai bună calitate datorită echipamentelor profesionale, detergenților speciali și tratamentelor pentru îndepărtarea petelor. Angajații noștri profesioniști fac diferența.",
        ],
        list: [
          "Spălăm și împăturim hainele tale, oferindu-ți mai mult timp liber.",
          "Luăm hainele de la ușă și le returnăm curate și împăturite. Umple sacoșa și gata!",
          "Folosim detergenți și echipamente de top pentru cea mai bună îngrijire a hainelor tale.",
        ],
        note:
          "Serviciul nostru de bază nu include călcatul și curățarea uscată",
      },
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-jeff-bag {
  .bag {
    padding: var(--spacer-sm) 0;
    display: flex;
    align-items: center;
    gap: var(--spacer-2xl);
    @include for-mobile {
      flex-wrap: wrap;
      padding: var(--spacer-sm);
      max-width: calc(100% - 2 * var(--spacer-sm));
      gap: var(--spacer-xl);
    }

    &__details {
      flex: 1 1 50%;
      &--title {
        font-size: 1.75rem;
        font-weight: var(--font-semibold);
        margin: 0 0 var(--spacer-base) 0;
        @include for-mobile {
          font-size: var(--font-lg);
          margin: var(--spacer-sm) 0 var(--spacer-xs) 0;
        }
      }
      &--description {
        font-size: var(--font-base);
        line-height: 1.4;
        margin: 0;
        &-container {
          margin: 0 0 var(--spacer-base) 0;
          &:last-child {
            margin: 0;
          }
        }
      }
      &--list {
        font-size: var(--font-base);
        line-height: 1.4;
        margin: 0;
        list-style: none;
        padding: 0;

        &-item {
          --icon-color: var(--c-primary);
          display: flex;
          align-items: center;
          &:not(:last-child) {
            margin-bottom: var(--spacer-base);
          }
          span {
            margin-left: var(--spacer-sm);
          }
        }
      }
      &--note {
        --icon-color: var(--c-primary-variant);
        --icon-size: 1.25rem;
        display: flex;
        align-items: center;
        opacity: 0.8;
        margin-top: var(--spacer-2xl);
        span {
          color: var(--c-gray-lighten);
          margin-left: var(--spacer-xs);
          font-size: var(--font-sm);
          margin-top: 2px;
        }
      }
    }
    &__image {
      flex: 1 1 50%;
      border-radius: 10px;
      overflow: hidden;
      filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.2));
      img {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow-clip-margin: unset;
      }
    }
  }
}
</style>
