<template>
  <div class="m-delivery">
    <aSection :title="section.title" :subtitle="section.subtitle">
      <div class="container delivery">
        <div class="delivery__details">
          <h3 class="delivery__details--title">{{ $t(details.title) }}</h3>
          <p class="delivery__details--description">
            {{ $t(details.description) }}
          </p>
          <div class="delivery__details--contact">
            <button
              class="delivery__details--button"
              @click="$router.push({ name: 'Contact' })"
            >
              {{ $t("Contactează-ne") }}
            </button>
            <a
              href="tel:40756724674"
              class="delivery__details--button-secondary"
            >
              <SfIcon icon="phone" color="white" />
            </a>
            <a
              href="https://wa.me/40756724674?text=Buna%20ziua,%20as%20dori%20sa%20fac%20o%20comanda."
              target="_blank"
              class="delivery__details--button-secondary"
            >
              <SfIcon icon="whatsapp" color="white" />
            </a>
          </div>
        </div>
        <div class="delivery__image">
          <img src="@/assets/images/webp/car.webp" alt="car" />
        </div>
      </div>
    </aSection>
  </div>
</template>
<script>
import { SfIcon } from "@storefront-ui/vue";
import aSection from "../../atoms/a-section.vue";

export default {
  components: {
    aSection,
    SfIcon,
  },
  data() {
    return {
      section: {
        title: "Curățare Profesională cu Ridicare și Livrare",
        subtitle: "Colectare și Livrare",
      },
      details: {
        title: "Servicii Profesionale de Curățare cu Ridicare și Livrare",
        description:
          "Ne ocupăm de toate textilele tale! Preluăm, spălăm, igienizăm și călcăm hainele tale, astfel încât tu să te poți bucura de mai mult timp liber. Lasă-ne pe noi să avem grijă de rufele tale, iar tu relaxează-te și bucură-te de lucrurile care contează cu adevărat!",
      },
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-delivery {
  .delivery {
    padding: var(--spacer-sm) 0;
    display: flex;
    align-items: center;
    gap: var(--spacer-3xl);
    @include for-mobile {
      flex-wrap: wrap;
      padding: var(--spacer-sm);
      max-width: calc(100% - 2 * var(--spacer-sm));
      gap: var(--spacer-xl);
    }

    &__details {
      flex: 1 1 50%;
      &--title {
        font-size: 1.75rem;
        font-weight: var(--font-semibold);
        margin: 0 0 var(--spacer-base) 0;
        @include for-mobile {
          font-size: var(--font-lg);
          margin: var(--spacer-sm) 0 var(--spacer-xs) 0;
        }
      }
      &--description {
        font-size: var(--font-base);
        line-height: 1.4;
        margin: 0;
      }
      &--contact {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: var(--spacer-sm);
        margin-top: var(--spacer-xl);
      }
      &--button {
        color: var(--c-white);
        background-color: var(--c-primary-darken);
        padding: 0.75rem var(--spacer-xl);
        height: 3rem;
        font-size: var(--font-lg);
        font-family: var(--font-family-secondary);
        border-radius: 200px;
        outline: none;
        border: none;
        width: 45%;
        filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.2));
        cursor: pointer;
        transition: 0.3s ease;
        @include for-mobile {
          width: 100%;
        }
        &:hover {
          background-color: #1e9acd;
        }
        &-secondary {
          --icon-size: 1.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 3rem;
          min-width: 3rem;
          background-color: var(--c-primary-darken);
          border-radius: 200px;
          outline: none;
          border: none;
          filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.2));
          cursor: pointer;
          transition: 0.3s ease;
          &:hover {
            background-color: #1e9acd;
          }
          &-container {
            display: flex;
            align-items: center;
            gap: var(--spacer-sm);
            @include for-mobile {
              width: 100%;
            }
          }
          @include for-mobile {
            flex: 1 1 auto;
          }
        }
      }
    }
    &__image {
      flex: 1 1 50%;
      border-radius: 10px;
      overflow: hidden;
      filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.2));
      img {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow-clip-margin: unset;
      }
    }
  }
}
</style>
