<template>
  <div class="about">
    <mPageTitle
      :title="$t(page.title)"
      :description="$t(page.description)"
      :image="require('@/assets/images/webp/about.webp')"
    />
    <mAbout />
    <mDelivery />
  </div>
</template>
<script>
import mPageTitle from "../components/molecules/m-page-title.vue";
import mDelivery from "../components/molecules/about-us/m-delivery.vue";
import mAbout from "../components/molecules/about-us/m-about.vue";

export default {
  metaInfo() {
    return {
      title: this.$t("Despre noi"),
    };
  },
  components: {
    mPageTitle,
    mDelivery,
    mAbout,
  },
  data() {
    return {
      page: {
        title: "Despre Noi",
        description:
          "La Mr Jeff, ne mândrim cu oferirea unor servicii de spălătorie de calitate superioară. Folosim detergenți premium și tehnologie avansată pentru a asigura rezultate impecabile de fiecare dată.",
      },
    };
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },
};
</script>
<style lang="scss" scoped>
.about {
  display: block;
}
</style>
